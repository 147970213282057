<svelte:window on:click="{closeAllDropdowns}" on:blur="{closeAllDropdowns}" />
{#if showTopNav}
<div class="fd-shellbar {hideNavComponent ? 'hideNavComponent' : ''}" tabindex="0">
  <div class="fd-shellbar__group fd-shellbar__group--product">
    {#if responsiveNavSetting==="simple" || responsiveNavSetting==="simpleMobileOnly" ||
    responsiveNavSetting==="Fiori3"}
    <button
      class="fd-shellbar__button fd-button lui-burger"
      on:click="{burgerClickHandler}"
      tabindex="0"
      title="{burgerTooltip}"
    >
      <i class="sap-icon sap-icon--menu2"></i>
    </button>
    {/if}
    <LogoTitle
      pathData="{pathData}"
      pathParams="{pathParams}"
      addNavHrefForAnchor="{addNavHrefForAnchor}"
      bind:dropDownStates
      on:toggleDropdownState="{toggleDropdownStateExternal}"
      on:handleClick="{handleClickExternal}"
    />
  </div>
  <div class="fd-shellbar__group fd-shellbar__group--actions">
    {#if !authorizationEnabled || isLoggedIn} {#if isGlobalSearchAvailable}
    <GlobalSearch
      bind:isSearchFieldVisible
      on:toggleSearch
      on:handleSearchNavigation
      bind:searchResult
      bind:displaySearchResult
      bind:displayCustomSearchResult
      bind:inputElem
      bind:luigiCustomSearchRenderer__slot
      on:closeSearchResult
    />
    {/if}
    <ContextSwitcher
      bind:dropDownStates
      on:toggleDropdownState="{() => toggleDropdownState('contextSwitcherPopover')}"
      isMobile="{false}"
      addNavHrefForAnchor="{addNavHrefForAnchor}"
    />
    {/if} {#if isGlobalSearchAvailable || (children && pathData.length > 0)} {#if
    (children && pathData.length > 0)} {#each children as node, i} {#if !(node.hideFromNav
    || (showGlobalNav && node.globalNav))} {#if node.isCat}
    <div
      class="fd-shellbar__action fd-shellbar__action--hide fd-shellbar__action--desktop"
    >
      <div class="fd-popover fd-popover--right">
        <div class="fd-popover__control" on:click|stopPropagation="{() => {}}">
          <button
            title="{resolveTooltipText(node, getNodeLabel(node))}"
            class="fd-shellbar__button fd-button {node === selectedNode ? 'is-selected' : ''}"
            aria-controls="dropDownPopover-{i}"
            aria-expanded="{dropDownStates[`dropDownPopover-${i}`] || false}"
            aria-haspopup="true"
            on:click="{() => toggleDropdownState(`dropDownPopover-${i}`)}"
            data-testid="{getTestId(node)}"
          >
            <TopNavNode bind:node />
            <BadgeCounter {node} />
          </button>
        </div>
        <div
          class="fd-popover__body fd-popover__body--right"
          aria-hidden="{!(dropDownStates[`dropDownPopover-${i}`] || false)}"
          id="dropDownPopover-{i}"
        >
          <TopNavDropDown
            node="{node}"
            isMobile="{false}"
            pathParams="{pathParams}"
            addNavHrefForAnchor="{addNavHrefForAnchor}"
          />
        </div>
      </div>
    </div>
    {:else}
    <div
      class="fd-shellbar__action fd-shellbar__action--hide fd-shellbar__action--desktop"
    >
      {#if addNavHrefForAnchor}
      <a
        href="{getRouteLink(node)}"
        class="fd-shellbar__button fd-button {node === selectedNode ? 'is-selected' : ''}"
        title="{resolveTooltipText(node, getNodeLabel(node))}"
        aria-expanded="false"
        aria-haspopup="true"
        on:click|preventDefault="{() => handleClick(node)}"
        data-testid="{getTestId(node)}"
      >
        <TopNavNode bind:node />
        <BadgeCounter {node} />
      </a>
      {:else}
      <button
        title="{resolveTooltipText(node, getNodeLabel(node))}"
        class="fd-shellbar__button fd-button {node === selectedNode ? 'is-selected' : ''}"
        aria-expanded="false"
        aria-haspopup="true"
        on:click="{() => handleClick(node)}"
        data-testid="{getTestId(node)}"
      >
        <TopNavNode bind:node />
        <BadgeCounter {node} />
      </button>
      {/if}
    </div>
    {/if} {/if} {/each} {/if}
    <!-- Sample Markup for Notifications
            <div class="fd-shellbar__action fd-shellbar__action--desktop">
              <button class="fd-shellbar__button fd-button sap-icon--bell" aria-label="Notifications">
                <span class="fd-counter fd-counter--notification fd-shellbar__counter--notification" aria-label="Unread count">25</span>
              </button>
            </div>
    -->

    <!-- dropdown for top nav nodes on smaller screens -->
    {#if (visibleNodeCount - globalNavNodeCount > 0) || isProductSwitcherAvailable ||
    contextSwitcherConfig || isGlobalSearchAvailable }
    <div class="fd-shellbar__action fd-shellbar__action--mobile">
      <div class="fd-shellbar-collapse">
        <div class="fd-popover fd-popover--right">
          <div class="fd-popover__control" on:click|stopPropagation="{() => {}}">
            <div
              class="fd-shellbar-collapse--control"
              aria-expanded="false"
              aria-haspopup="true"
              role="button"
            >
              <button
                class="fd-shellbar__button fd-button"
                aria-controls="overflowPopover"
                aria-expanded="{dropDownStates.overflowPopover || false}"
                aria-haspopup="true"
                on:click="{() => toggleDropdownState('overflowPopover')}"
                data-testid="mobile-menu"
              >
                <i class="sap-icon sap-icon--overflow"></i>
                <BadgeCounter node="{totalBadgeNode}" special="true" />
              </button>
            </div>
          </div>
          <div
            class="fd-popover__body fd-popover__body--right"
            aria-hidden="{!(dropDownStates.overflowPopover || false)}"
            id="overflowPopover"
          >
            <nav class="fd-menu">
              <ul class="fd-menu__list fd-menu__list--no-shadow">
                {#if isGlobalSearchAvailable}
                <li class="fd-menu__item">
                  <a
                    class="fd-menu__link"
                    on:click|stopPropagation="{()=>{toggleSearch(),toggleDropdownState('overflowPopover')}}"
                    data-testid="luigi-search-btn-mobile"
                  >
                    <i class="sap-icon sap-icon--search fd-top-nav__icon"></i>
                    <span class="fd-menu__title">Search</span>
                  </a>
                </li>
                {/if} {#if contextSwitcherConfig && (!authorizationEnabled || isLoggedIn)}
                <li class="fd-menu__item">
                  <a
                    class="fd-menu__link"
                    on:click|stopPropagation="{openMobileContextSwitcher}"
                  >
                    <i
                      class="sap-icon fd-top-nav__icon {contextSwitcherConfig.icon && hasOpenUIicon(contextSwitcherConfig) ? 'sap-icon--' + contextSwitcherConfig.icon : 'sap-icon--switch-views'}"
                    ></i>
                    <span class="fd-menu__title"
                      >{selectedLabel ? selectedLabel : defaultLabelContextSwitcher}</span
                    >
                  </a>
                </li>
                {/if} {#if children} {#each children as node,i} {#if !(node.hideFromNav ||
                (showGlobalNav && node.globalNav))} {#if !node.isCat}
                <li class="fd-menu__item">
                  <a
                    href="{getRouteLink(node)}"
                    class="fd-menu__link {node === selectedNode ? 'is-selected' : ''}"
                    on:click|preventDefault="{() => handleClick(node)}"
                    data-testid="{getTestId(node)}-mobile"
                  >
                    <span
                      class="fd-top-nav__icon sap-icon {node.icon && hasOpenUIicon(node) ? 'sap-icon--' + node.icon : '' }"
                    >
                      {#if !hasOpenUIicon(node)}
                      <img src="{node.icon}" alt="{node.altText ? node.altText : ''}" />
                      {/if}
                      <BadgeCounter {node} />
                    </span>
                    <span class="fd-menu__title">{getNodeLabel(node)}</span>
                  </a>
                </li>
                {:else}
                <li class="fd-menu__item">
                  <a
                    href="{getRouteLink(node)}"
                    title="{resolveTooltipText(node, getNodeLabel(node))}"
                    class="fd-menu__link"
                    on:click|preventDefault="{() => openMobileTopNavDropDown(node)}"
                    data-e2e="mobile-topnav-dropdown-category"
                  >
                    <span class="fd-top-nav__icon">
                      {#if hasOpenUIicon(node)}
                      <i
                        class="sap-icon {node.icon && hasOpenUIicon(node) ? 'sap-icon--' + node.icon : '' }"
                      ></i>
                      {:else}
                      <img src="{node.icon}" alt="{node.altText ? node.altText : ''}" />
                      {/if}
                      <BadgeCounter {node} />
                    </span>
                    <span class="fd-list__title">{getNodeLabel(node)}</span>
                  </a>
                </li>
                <!-- {:else}
                <li class="fd-menu__item">
                  <a
                    href="{getRouteLink(node)}"
                    title="{getNodeLabel(node)}"
                    class="fd-menu__link"
                    on:click|preventDefault="openMobileTopNavDropDown(node)"
                    data-testid="mobile-topnav-dropdown-category"
                  >
                    {#if node.icon} {#if hasOpenUIicon(node)}
                    <span
                      class="fd-top-nav__icon sap-icon sap-icon--{node.icon}"
                    ></span>
                    {:else}
                    <img
                      class="fd-top-nav__icon nav-icon"
                      src="{node.icon}"
                    >
                    {/if} {/if}
                    <span class="fd-list__title">{getNodeLabel(node)}</span>
                  </a>
                </li>-->
                <!-- {/if} -->
                {/if} {/if} {/each} {/if} {#if isProductSwitcherAvailable}
                <li class="fd-menu__item">
                  <a
                    class="fd-menu__link"
                    on:click|stopPropagation="{openMobileProductSwitcher}"
                    data-testid="mobile-product-switcher"
                  >
                    {#if hasOpenUIicon(productSwitcherConfig) ||
                    !productSwitcherConfig.icon}
                    <i
                      class="fd-top-nav__icon sap-icon sap-icon--{productSwitcherConfig.icon || 'grid'}"
                    ></i>
                    {:else}
                    <span class="fd-top-nav__icon sap-icon">
                      <img
                        src="{productSwitcherConfig.icon}"
                        alt="{productSwitcherConfig.altText ? productSwitcherConfig.altText : ''}"
                      />
                    </span>
                    {/if}
                    <span class="fd-menu__title">{productSwitcherConfig.label}</span>
                  </a>
                </li>
                {/if}
              </ul>
            </nav>
          </div>
          {#if isProductSwitcherAvailable}
          <ProductSwitcher
            bind:dropDownStates
            on:toggleDropdownState="{() => toggleDropdownState('productSwitcherPopover')}"
            isMobile="{true}"
          />
          {/if} {#if openMobileDropDown}
          <TopNavDropDown
            node="{nodeForMobile}"
            isMobile="{true}"
            on:close="{closeMobileTopNavDropDown}"
            pathParams="{pathParams}"
          />
          {/if} {#if !authorizationEnabled || isLoggedIn}
          <ContextSwitcher
            bind:dropDownStates
            on:toggleDropdownState="{() => toggleDropdownState('contextSwitcherPopover')}"
            isMobile="{true}"
            contextSwitcherToggle="{contextSwitcherToggle}"
            bind:selectedLabel="{selectedLabel}"
            bind:defaultLabel="{defaultLabelContextSwitcher}"
          />
          {/if}
        </div>
      </div>
    </div>
    {/if} {/if}
    <!-- closes {#if children && pathData.length > 0} -->
    {#if authorizationEnabled || profileItemsAvailable}
    <div
      class="fd-shellbar__action fd-shellbar__action--show-always"
      data-testid="luigi-topnav-profile"
    >
      {#if profileTypeSettings === "Fiori3" &&
      GenericHelpers.requestExperimentalFeature('profileMenuFiori3', true) }
      <div class="fd-popover fd-popover--right fd-user-menu">
        <div class="fd-popover__control" on:click|stopPropagation="{() => {}}">
          {#if userInfo.picture}
          <button
            class="fd-avatar fd-avatar--xs fd-avatar--circle fd-avatar--thumbnail"
            aria-controls="profilePopover"
            aria-expanded="true"
            aria-haspopup="true"
            title="{userInfo.name ? userInfo.name : undefined}"
            tabindex="0"
            style="background-image:url('{userInfo.picture}')"
            on:click="{() => toggleDropdownState('profilePopover')}"
            data-testid="luigi-topnav-profile-btn"
          ></button>
          {:else}
          <button
            class="fd-avatar fd-avatar--xs fd-avatar--circle fd-avatar--thumbnail"
            aria-expanded="true"
            aria-haspopup="true"
            title="{userInfo.name ? userInfo.name : undefined}"
            tabindex="0"
            on:click="{() => toggleDropdownState('profilePopover')}"
            data-testid="luigi-topnav-profile-initials"
          >
            {userInfo.initials ? userInfo.initials : ''}
          </button>
          {/if}
        </div>
        <div
          class="fd-popover__body fd-popover__body--right"
          aria-hidden="{!(dropDownStates.profilePopover || false)}"
          id="profilePopover"
        >
          <Authorization
            on:toggleDropdownState="{() => toggleDropdownState('profilePopover')}"
            on:userInfoUpdated="{userInfoUpdate}"
            addNavHrefForAnchor="{addNavHrefForAnchor}"
          />
        </div>
      </div>
      {:else}
      <div class="fd-user-menu">
        <div class="fd-popover">
          <div class="fd-popover__control" on:click|stopPropagation="{() => {}}">
            <div class="{userInfo.picture ? 'fd-shellbar__button--user-menu' : '' }">
              <button
                class="fd-button fd-shellbar__button"
                aria-expanded="{dropDownStates.profilePopover || false}"
                aria-haspopup="true"
                aria-controls="profilePopover"
                on:click="{() => toggleDropdownState('profilePopover')}"
                title="{userInfo.name ? userInfo.name : undefined}"
                tabindex="0"
                data-testid="luigi-topnav-profile-btn"
              >
                {#if userInfo.picture}
                <span
                  class="fd-avatar fd-avatar--xs fd-avatar--circle"
                  style="background-image:url('{userInfo.picture}')"
                ></span>
                {:else}
                <i
                  class="sap-icon {!userInfo.picture?'sap-icon--customer':'fd-identifier fd-identifier--xs fd-identifier--circle'}"
                ></i>
                {/if}
              </button>
            </div>
          </div>
          <div
            class="fd-popover__body fd-popover__body--right"
            aria-hidden="{!(dropDownStates.profilePopover || false)}"
            id="profilePopover"
          >
            <AuthorizationSimpleProfileMenu
              on:toggleDropdownState="{() => toggleDropdownState('profilePopover')}"
              on:userInfoUpdated="{userInfoUpdate}"
              addNavHrefForAnchor="{addNavHrefForAnchor}"
            />
          </div>
        </div>
      </div>
      {/if}
    </div>
    {/if} {#if isProductSwitcherAvailable}
    <ProductSwitcher
      bind:dropDownStates
      on:toggleDropdownState="{() => toggleDropdownState('productSwitcherPopover')}"
      isMobile="{false}"
      addNavHrefForAnchor="{addNavHrefForAnchor}"
    />
    {/if}
  </div>
</div>
{:else}
<AuthorizationSimpleProfileMenu
  on:toggleDropdownState="{() => toggleDropdownState('profilePopover')}"
  isHidden="{true}"
  addNavHrefForAnchor="{addNavHrefForAnchor}"
/>
{/if}
<script>
  import LogoTitle from './LogoTitle.html';
  import BadgeCounter from './BadgeCounter.html';
  import Authorization from '../Authorization.html';
  import AuthorizationSimpleProfileMenu from '../AuthorizationSimpleProfileMenu.html';
  import TopNavDropDown from '../TopNavDropDown.html';
  import ContextSwitcher from './ContextSwitcher.html';
  import ProductSwitcher from './ProductSwitcher.html';
  import GlobalSearch from './GlobalSearch.html';
  import TopNavNode from './TopNavNode.html';
  import { beforeUpdate, createEventDispatcher, onMount, getContext } from 'svelte';
  import { LuigiAuth, LuigiConfig, LuigiI18N } from '../core-api';
  import {
    AuthHelpers,
    NavigationHelpers,
    RoutingHelpers,
    StateHelpers,
    EventListenerHelpers,
    GenericHelpers
  } from '../utilities/helpers';
  import { SemiCollapsibleNavigation } from './services/semi-collapsed-navigation';

  const dispatch = createEventDispatcher();

  export let authorizationEnabled;
  export let autologinEnabled;
  export let isLoggedIn = false;
  export let hideNavComponent;
  export let responsiveNavSetting;
  export let profileTypeSettings;
  export let showGlobalNav;
  export let pathData;
  let previousPathData;
  export let pathParams;
  export let dropDownStates = {};
  export let children;
  export let selectedNode;
  export let visibleNodeCount;
  export let globalNavNodeCount;
  export let totalBadgeNode;
  export let isProductSwitcherAvailable;
  export let productSwitcherConfig;
  export let openMobileDropDown;
  export let nodeForMobile;
  export let profileItemsAvailable;
  export let userInfo = {};
  export let urlAuthError;
  export let isGlobalSearchAvailable;
  export let isSearchFieldVisible;
  export let inputElem;
  export let luigiCustomSearchRenderer__slot;
  export let displaySearchResult;
  export let displayCustomSearchResult;
  export let searchResult;
  export let burgerTooltip;

  let store = getContext('store');
  let contextSwitcherToggle = false;
  let selectedLabel;
  let defaultLabelContextSwitcher;
  let contextSwitcherConfig = LuigiConfig.getConfigValue('navigation.contextSwitcher');
  export let addNavHrefForAnchor = LuigiConfig.getConfigBooleanValue(
    'navigation.addNavHrefs'
  );
  const setTopNavData = async () => {
    if (pathData && 0 < pathData.length) {
      const tnd = await NavigationHelpers.generateTopNavNodes(pathData);
      children = tnd.children;
      selectedNode = tnd.selectedNode;
      visibleNodeCount = tnd.visibleNodeCount;
      totalBadgeNode = tnd.totalBadgeNode;
      globalNavNodeCount = tnd.globalNavNodeCount;
      window.TOPNAVDATA = tnd.children;
      previousPathData = pathData;
    }
  };

  const setLoggedInState = () => {
    isLoggedIn = AuthHelpers.isLoggedIn();
  };

  onMount(() => {
    StateHelpers.doOnStoreChange(
      store,
      () => {
        authorizationEnabled = LuigiAuth.isAuthorizationEnabled();
        profileItemsAvailable = LuigiConfig.getConfigValue('navigation.profile');
        autologinEnabled = !Boolean(LuigiConfig.getConfigValue('auth.disableAutoLogin'));
        isProductSwitcherAvailable = LuigiConfig.getConfigValue(
          'navigation.productSwitcher'
        );
        hideNavComponent = LuigiConfig.getConfigBooleanValue('settings.hideNavigation');
        responsiveNavSetting = LuigiConfig.getConfigValue(
          'settings.responsiveNavigation'
        );
        profileTypeSettings = LuigiConfig.getConfigValue('settings.profileType');
        productSwitcherConfig = NavigationHelpers.getProductSwitcherConfig();
        isGlobalSearchAvailable = LuigiConfig.getConfigValue('globalSearch');
        showGlobalNav =
          LuigiConfig.getConfigBooleanValue('settings.globalSideNavigation') &&
          GenericHelpers.requestExperimentalFeature('globalNav', true);
        addNavHrefForAnchor = LuigiConfig.getConfigBooleanValue('navigation.addNavHrefs');
        contextSwitcherConfig = LuigiConfig.getConfigValue('navigation.contextSwitcher');
      },
      ['navigation']
    );

    EventListenerHelpers.addEventListener('message', e => {
      if ('luigi.navigation.update-badge-counters' === e.data.msg) {
        setTopNavData();
      }
    });
  });

  beforeUpdate(() => {
    if (!previousPathData || previousPathData != pathData) {
      setTopNavData();
    }
    setLoggedInState();
  });

  export let showTopNav;
  $: showTopNav =
    (authorizationEnabled && (!autologinEnabled || isLoggedIn)) || !authorizationEnabled;

  function hasOpenUIicon(node) {
    return NavigationHelpers.isOpenUIiconName(node.icon);
  }

  function getNodeLabel(node) {
    return LuigiI18N.getTranslation(node.label);
  }

  function getTestId(node) {
    return node.testId
      ? node.testId
      : NavigationHelpers.prepareForTests(node.pathSegment, node.label);
  }

  function getRouteLink(node) {
    return RoutingHelpers.getNodeHref(node, pathParams);
  }

  function resolveTooltipText(node, translation) {
    return NavigationHelpers.generateTooltipText(node, translation);
  }

  export function openMobileProductSwitcher() {
    toggleDropdownState('productSwitcherPopover');
  }

  export function openMobileContextSwitcher() {
    contextSwitcherToggle = !contextSwitcherToggle;
    toggleDropdownState('contextSwitcherPopover');
  }

  export function openMobileTopNavDropDown(node) {
    openMobileDropDown = true;
    nodeForMobile = node;
  }

  export function closeMobileTopNavDropDown() {
    openMobileDropDown = false;
  }

  export function handleClick(node) {
    dispatch('handleClick', { node });
  }

  export function handleSearchNavigation(node) {
    dispatch('handleSearchNavigation', { node });
  }

  export function handleClickExternal(event) {
    handleClick(event.detail.node);
  }

  export function toggleDropdownState(name) {
    const ddStates = dropDownStates || {};
    const dropDownState = !ddStates[name];

    closeAllDropdowns();
    closeMobileTopNavDropDown();
    ddStates[name] = dropDownState;

    dropDownStates = ddStates;
  }

  export function toggleDropdownStateExternal(event) {
    toggleDropdownState(event.detail.name);
  }

  function setFocusOnGlobalSearchFieldMobile() {
    if (inputElem) {
      inputElem.focus();
    }
  }

  export function toggleSearch() {
    if (!isSearchFieldVisible)
      setTimeout(() => {
        setFocusOnGlobalSearchFieldMobile();
      });
    dispatch('toggleSearch', {
      isSearchFieldVisible,
      inputElem,
      luigiCustomSearchRenderer__slot
    });
  }

  export function closeAllDropdowns() {
    const ddStates = dropDownStates || {};
    const keys = Object.keys(ddStates);
    if (keys && keys.length > 0) {
      keys.forEach(k => {
        ddStates[k] = false;
        dropDownStates = ddStates;
      });
    }
  }

  function burgerClickHandler() {
    if (
      responsiveNavSetting === 'simple' ||
      responsiveNavSetting === 'simpleMobileOnly'
    ) {
      simpleNav();
    } else {
      semicollapsedNav();
    }
    setBurgerTooltip();
  }

  function setBurgerTooltip() {
    if (!NavigationHelpers.getBurgerTooltipConfig()) {
      return;
    }
    const [
      collapseNavTooltip,
      expandNavTooltip
    ] = NavigationHelpers.getBurgerTooltipConfig();
    if (collapseNavTooltip && expandNavTooltip) {
      if (document.body.classList.contains('lui-simpleSlideInNav')) {
        burgerTooltip = document.body.classList.contains('lui-leftNavToggle')
          ? collapseNavTooltip
          : expandNavTooltip;
      }
      if (document.body.classList.contains('lui-semiCollapsible')) {
        burgerTooltip = document.body.classList.contains('semiCollapsed')
          ? collapseNavTooltip
          : expandNavTooltip;
      }
    }
  }

  export function simpleNav() {
    document.body.classList.toggle('lui-leftNavToggle');
    if (document.getElementsByClassName('fd-tabs').length > 0) {
      dispatch('resizeTabNav', {});
    }
  }

  export function semicollapsedNav() {
    SemiCollapsibleNavigation.buttonClicked();
    if (document.getElementsByClassName('fd-tabs').length > 0) {
      dispatch('resizeTabNav', {});
    }
  }

  export function userInfoUpdate(event) {
    const uInfo = event.detail;
    userInfo = uInfo ? uInfo : {};
  }
</script>

<style type="text/scss">/*Remove Safari bug with blue outlines of dropdowns in the shellbar*/
.fd-shellbar:focus {
  outline: none; }

.fd-shellbar {
  padding: 0 0.5rem; }

.hideNavComponent {
  display: none; }

.nav-icon {
  height: 100%; }

.fd-top-nav__icon img {
  max-height: 16px;
  vertical-align: top;
  max-width: 16px; }

img.fd-top-nav__icon {
  height: 16px;
  min-width: auto; }

.fd-popover .nav-icon {
  height: 2em; }

.lui-burger {
  cursor: pointer;
  color: var(--sapShell_TextColor, #fff); }

@media (min-width: 600px) {
  :global(.lui-mobileOnly) .lui-burger {
    display: none; } }

:global(.no-side-nav) .lui-burger {
  display: none; }

.fd-top-nav__icon {
  display: inline-block;
  vertical-align: middle;
  min-width: 16px; }

.fd-user-menu .fd-shellbar__button {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.fd-user-menu .fd-avatar {
  cursor: pointer; }

/*# sourceMappingURL=x.map */</style>
